<template>
  <div class="container">
    <el-table
      :data="datalist"
      :header-cell-style="{
        background: 'rgba(245,245,245,1)',
        color: '#333333'
      }"
    >
      <el-table-column label="课程" prop="course_name" />
      <el-table-column label="章节" prop="schedule_name" />
      <el-table-column label="进入时间">
        <template slot-scope="scope">
          {{ scope.row.last_active_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column :render-header="renderHeader" width="190">
        <template slot-scope="scope">
          <span style="float:left">
            {{ scope.row.play_duration | formatTimeLength }}分钟
          </span>
        </template>
      </el-table-column>
      <el-table-column :render-header="renderHeader2" prop="play_times" />
      <el-table-column :render-header="renderHeader3" width="140px">
        <template slot-scope="scope">
          <!-- 1 H5 2 pc浏览器 3 小程序 -->
          <div v-if="scope.row.device == 1">微信H5</div>
          <div v-if="scope.row.device == 2">pc浏览器</div>
          <div v-if="scope.row.device == 3">小程序</div>
        </template>
      </el-table-column>
      <el-table-column label="结算时间">
        <template slot-scope="scope">
          {{ scope.row.clearing_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="消耗叮豆" prop="clearing_price" />
    </el-table>
    <!--分页-->
    <pagination
      :currPage="nowPage"
      :total="allNum"
      @currentChange="handleCurrentChange"
    ></pagination>
    <!-- <div style="width:100%;margin:30px auto;text-align:center;">
      <el-pagination
        background
        :current-page="nowPage"
        layout="prev, pager, next"
        :total="allNum"
        v-if="allNum > 5"
        :page-sizes="[5]"
        :page-size="15"
        @current-change="handleCurrentChange"
      >
    </el-pagination>-->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      allNum: '',
      datalist: [], //录播
      totals: 0, // 总数量
      nowPage: 1 //当前页数
    }
  },
  methods: {
    renderHeader() {
      return (
        <div>
          <span>学习时长</span>
          <el-popover
            placement="top"
            width="150"
            popper-class="popover"
            trigger="hover"
            content="学员观看课程时长"
          >
            <i
              class="el-icon-question"
              slot="reference"
              style="color:#CCCCCC"
            ></i>
          </el-popover>
        </div>
      )
    },
    renderHeader2() {
      return (
        <div>
          <span>学习次数</span>
          <el-popover
            placement="top"
            width="258"
            popper-class="popover"
            trigger="hover"
            content="学员重复点击播放会被重复记录学习次数"
          >
            <i
              class="el-icon-question"
              slot="reference"
              style="color:#CCCCCC"
            ></i>
          </el-popover>
        </div>
      )
    },
    renderHeader3() {
      return (
        <div>
          <span>最后学习终端</span>
          <el-popover
            placement="top"
            width="340"
            popper-class="popover"
            trigger="hover"
            content="学员使用了多种终端学习，系统只记录最后一次的终端"
          >
            <i
              class="el-icon-question"
              slot="reference"
              style="color:#CCCCCC"
            ></i>
          </el-popover>
        </div>
      )
    },
    handleCurrentChange(val) {
      //点击切换分页
      const that = this
      that.nowPage = val
      that.getDate()
    },
    getDate() {
      const that = this
      this.$http({
        url: '/User/livePlayRecord',
        data: {
          uid: this.uid,
          page: this.nowPage
        },
        callback: ({ data, code, allNum }) => {
          if (code == 200) {
            that.datalist = data
            that.allNum = Number(allNum)
          }
        },
        error: error => {
          if (error.code == 204) {
            that.datalist = []
          }
        }
      })
    }
  },
  created() {
    this.getDate()
  }
}
</script>

<style lang="less" scoped>
.container{
  padding: 0px 20px;
}
.el-button--text {
  color: #1b9d97;
}
.name-dt {
  float: left;
  margin-right: 10px;
}
.name-dt img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
}
.name-dd-txt {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  padding-left: 44px;
}
.name-dd {
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
  padding-left: 44px;
}
/deep/ .el-button--primary {
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-button.el-button--default:hover {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
/deep/ .el-tag.el-tag--success {
  width: 68px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid rgba(10, 162, 155, 1);
  color: rgba(10, 162, 155, 1);
  background: #fff;
}
/deep/ .el-tag.el-tag--info.el-tag--plain {
  margin: 5px;
}
/deep/ .el-tag.el-tag--warning.el-tag--plain {
  text-align: center;
  border: 1px solid rgba(10, 162, 155, 1);
  margin: 5px;
  background: rgba(10, 162, 155, 1);
  border-radius: 4px;
  color: white;
}
/deep/ .el-dialog.add-tag {
  height: 350px;
}
.head-left {
  float: left;
}
.head-right {
  height: 40px;
  float: right;
  margin-top: 10px;
}
.head-left .el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.head-left .el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .set-popover {
  height: 340px !important;
}
/deep/ .tag-button {
  white-space: normal;
  font-size: 14px;
  color: rgba(10, 162, 155, 1);
  line-height: 19px;
  text-align: left;
}
</style>
