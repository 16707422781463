<template>
  <div>
    <template>
      <el-card class="box-card">
        <div class="item">
          <el-tabs v-model="activeName" @tab-click="changeTabs">
            <el-tab-pane label="录播观看记录" name="one">
              <record
                :type="type"
                v-if="activeName == 'one'"
                :uid="$route.query.uid"
              />
            </el-tab-pane>
            <el-tab-pane label="回放观看记录" name="two">
              <record2
                :type="type"
                v-if="activeName == 'two'"
                :uid="$route.query.uid"
              />
            </el-tab-pane>
            <!-- <el-tab-pane label="直播观看记录" name="third">
              <wish :type="type" v-if="activeName == 'third'" />
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </el-card>
    </template>
    <router-view />
  </div>
</template>

<script>
import record from '../detail/learning/record3'
import record2 from '../detail/learning/record2'
// import wish from '../detail/learning/wish' //敬请期待
import background from '@/mixin/background'

export default {
  components: {
    record2,
    record
    // wish
  },

  mixins: [background],

  data() {
    return {
      dateList: [], //观看记录列表
      activeName: 'one', //默认全部
      type: 5, // 默认显示全部
      tabsData: [
        { label: '录播观看记录', name: 'first' },
        { label: '回放观看记录', name: 'second' }
        // { label: '直播观看记录', name: 'third' }
      ]
    }
  },
  created: {},
  methods: {
    changeTabs() {
      // this.$nextTick(() => {
      if (this.activeName == 'one') {
        this.type = 5
      } else if (this.activeName == 'two') {
        this.type = 1
      } else if (this.activeName == 'third') {
        this.type = 2
      }
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
}
.el-button--text {
  color: #1b9d97;
}
/deep/.el-card__body {
  padding: 0px !important;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
/deep/ .el-tabs__content {
  margin-top: 30px;
}
// /deep/ .el-tabs.el-tabs--top {
// }
/deep/ #tab-first {
  padding-left: 20px;
}
.el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-input.el-input--suffix {
  width: 120px;
}
/deep/ #tab-one {
  padding-left: 20px !important;
}
</style>
